<!-- faq section -->
<section id="faq" class="saas1 faq testimonial-bg">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="faq-block">
                    <div>
                        <h3 class="frequent-text">Frequently Asked Questions</h3>
                        <h6>Our users are impatient. They're probably distracted too. Keep it simple and beautiful, fun
                            and
                            functional. Clean aesthetics supported by a strong concept is what we stand for.</h6>
                        <div class="accordion faq" id="accordionExample">
                            <div class="card">
                                    <ngb-accordion [closeOthers]="true" activeIds="static-1">
                                            <ngb-panel id="static-1">
                                                    <ng-template ngbPanelTitle>              
                                                           <a>
                                                               <div class="fa fa-angle-right rotate"></div>
                                                           </a>
                                                           Do I need to make a payment?
                                                          </ng-template>
                                              <ng-template ngbPanelContent>
                                                            No one cares about products. People care about ideas. Is a product an idea?
                                                            Noup. Is a brand? A good one is. No one cares about products. People care about ideas. Is a product an idea?
                                                            Noup. Is a brand? A good one is.
                                            </ng-template>
                                            </ngb-panel>
                                            <ngb-panel id="static-2">
                                                    <ng-template ngbPanelTitle>
                                                            
                                                           <a>
                                                               <div class="fa fa-angle-right rotate"></div>
                                                           </a>
                                                           Do I need to make a payment?
                                                       
                                                          </ng-template>
                                              <ng-template ngbPanelContent>
                                                    
                                                            No one cares about products. People care about ideas. Is a product an idea?
                                                            Noup. Is a brand? A good one is. No one cares about products. People care about ideas. Is a product an idea?
                                                            Noup. Is a brand? A good one is.
                                                       
                                            </ng-template>
                                            </ngb-panel>
                                            <ngb-panel id="static-3">
                                                    <ng-template ngbPanelTitle>
                                                           <a>
                                                               <div class="fa fa-angle-right rotate"></div>
                                                           </a>
                                                           Do I need to make a payment?
                                                          </ng-template>
                                              <ng-template ngbPanelContent>
                                                            No one cares about products. People care about ideas. Is a product an idea?
                                                            Noup. Is a brand? A good one is. No one cares about products. People care about ideas. Is a product an idea?
                                                            Noup. Is a brand? A good one is.
                                            </ng-template>
                                            </ngb-panel>
                                            <ngb-panel id="static-4">
                                                    <ng-template ngbPanelTitle>
                                                           <a>
                                                               <div class="fa fa-angle-right rotate"></div>
                                                           </a>
                                                           Do I need to make a payment?
                                                          </ng-template>
                                              <ng-template ngbPanelContent>
                                                            No one cares about products. People care about ideas. Is a product an idea?
                                                            Noup. Is a brand? A good one is. No one cares about products. People care about ideas. Is a product an idea?
                                                            Noup. Is a brand? A good one is.
                                            </ng-template>
                                            </ngb-panel>
                                            <ngb-panel id="static-5">
                                                    <ng-template ngbPanelTitle>
                                                           <a>
                                                               <div class="fa fa-angle-right rotate"></div>
                                                           </a>
                                                           Do I need to make a payment?
                                                          </ng-template>
                                              <ng-template ngbPanelContent>
                                                            No one cares about products. People care about ideas. Is a product an idea?
                                                            Noup. Is a brand? A good one is. No one cares about products. People care about ideas. Is a product an idea?
                                                            Noup. Is a brand? A good one is.
                                            </ng-template>
                                            </ngb-panel>
                                        </ngb-accordion>
                            </div>
                         </div>
                        <h6 class="link">Still have a question? Reach out to us: <a>demo@123.com</a></h6>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="faq-img-block">
                    <img src="assets/images/saas1/faq-img.png" class="img-fluid" alt="faq-person">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- end faq section -->
<ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
	<ngb-panel title="Simple">
		<ng-template ngbPanelContent>
			Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon
			officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf
			moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim
			keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
			vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't
			heard of them accusamus labore sustainable VHS.
		</ng-template>
	</ngb-panel>
	<ngb-panel>
		<ng-template ngbPanelTitle>
			<span>&#9733; <b>Fancy</b> title &#9733;</span>
		</ng-template>
		<ng-template ngbPanelContent>
			Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon
			officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf
			moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim
			keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
			vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't
			heard of them accusamus labore sustainable VHS.
		</ng-template>
	</ngb-panel>
	<ngb-panel title="Disabled" [disabled]="true">
		<ng-template ngbPanelContent>
			Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon
			officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf
			moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim
			keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
			vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't
			heard of them accusamus labore sustainable VHS.
		</ng-template>
	</ngb-panel>
</ngb-accordion>