import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modern-sass-services',
  templateUrl: './modern-sass-services.component.html',
  styleUrls: ['./modern-sass-services.component.scss']
})
export class ModernSassServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  services = [
    {
      img:'assets/images/saas2/our-feature/1.png',
      title:'Plataforma intuitiva',
      description:'Rapidez, layout otimizado e acessibilidade via celular ou computador'
    },
    {
      img:'assets/images/saas2/our-feature/2.png',
      title:'Sistema de gerenciamento',
      description:'Em breve teremos um sistema de gerenciamento completo de surebets, esqueça planilhas!'
    },
    {
      img:'assets/images/saas2/our-feature/3.png',
      title:'Suporte e comunidade',
      description:'Temos um grupo VIP no Telegram onde tiramos dúvidas e você se conecta com outros Blitanos'
    },
    {
      img:'assets/images/saas2/our-feature/4.png',
      title:'Diversas oportunidades',
      description:'40 casas de apostas de todo o mundo para encontrar a melhor oportunidade de Surebets para você'
    },
    {
      img:'assets/images/saas2/our-feature/5.png',
      title:'Atualizações',
      description:'Buscamos sempre melhoria contínua do software, melhoramos ele de acordo com seu feedback'
    },
    {
      img:'assets/images/saas2/our-feature/6.png',
      title:'Conhecimento gratuito',
      description:'Temos um blog e um canal no Telegram onde disponibilizamos conteúdos gratuitos sobre surebets'
    }
  ]
}
