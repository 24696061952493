import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprice-sass-work',
  templateUrl: './enterprice-sass-work.component.html',
  styleUrls: ['./enterprice-sass-work.component.scss']
})
export class EnterpriceSassWorkComponent implements OnInit {
  public work: any = 'Card de surebet'
  constructor() { }

  ngOnInit() {
  }

  openWorkSlide(val){
    this.work = val
  }

  nav = [
    {
      img:'assets/images/saas1/tab-icon/installation.png',
      title:'Card de surebet'
    },
    {
      img:'assets/images/saas1/tab-icon/001-tap.png',
      title:'Calculadora manual'
    },
    {
      img:'assets/images/saas1/tab-icon/button.png',
      title:'Filtro'
    },
    {
      img:'assets/images/saas1/tab-icon/002-settings.png',
      title:'Calculadora auto.'
    },
    {
      img:'assets/images/saas1/tab-icon/key-1.png',
      title:'Blog'
    }
  ]

  content=[
    {
      img:'assets/images/saas1/tab/Initializing.png',
      title:'Card de surebet'
    },
    {
      img:'assets/images/saas1/tab/Add-instant.png',
      title:'Calculadora manual'
    },
    {
      img:'assets/images/saas1/tab/Configuration.png',
      title:'Filtro'
    },
    {
      img:'assets/images/saas1/tab/Api.png',
      title:'Calculadora auto.'
    },
    {
      img:'assets/images/saas1/tab/Security.png',
      title:'Blog'
    }
  ]

}
