<!--header css start-->
<section class="saas2 header" id="home">
  <div class="saas2-content ">
      <div class="bg saas2-bg"  [ngStyle]="{'background-image': 'url(assets/images/saas2/slider.png)'}">
          <main class="container">
              <div class="row">
                  <div class="col-lg-6">
                      <div class="center-text">
                          <div class="">
                              <div class="header-text">
                                  <h1><span class="theme-color">Software Surebet</span></h1>
                              </div>
                              <div class="header-sub-text">
                                  <h3 class="saas2-sub-text">Experimente Grátis por <span class="bold-text">3 Dias!</span>
                                  </h3>
                              </div>
                              <div class="header-sub-text">
                                  <p class="sub-para text-white">O único software de surebet grátis. Teste gratuitamente agora! Busca de novas surebets 24h por dia, em mais de 40 casas de apostas. Não é grupo de sinais, é um avançado sistema de IA com uma calculadora de surebet funcionando a todo momento!</p>
                              </div>
                              <a class="btn btn-default success-btn success btn-11 my-button-not-hide" target="_blank" href="https://blit.bet">Acessar Software</a>
                          </div>
                      </div>
                  </div>
                  <div class="center-text set-abs set-abs3 overlapping-div">
                      <img alt="surebet software" src="assets/images/saas2/dextop2.png" class="img-fluid img-dextop">
                  </div>
              </div>
            </main>

          <img alt="icon" src="assets/images/saas1/background2.png"
               class="img-fluid set-abs background-animate">
          <!-- <img alt="icon" src="assets/images/saas2/header-icon/3.png"
               class="img-fluid set-abs img1 move-up-down"> -->
          <!-- <img alt="" src="assets/images/saas2/header-icon/2.png"
               class="img-fluid set-abs img2 move-right-left"> -->
          <!-- <img alt="icon" src="assets/images/saas2/header-icon/4.png" -->
               <!-- class="img-fluid set-abs img3 move-up-down"> -->
          <img alt="icon" src="assets/images/saas2/header-icon/5.png"
               class="img-fluid set-abs img4 move-up-down">
          <img alt="icon" src="assets/images/saas2/header-icon/7.png"
               class="img-fluid set-abs img5 move-right-left">
          <img alt="icon" src="assets/images/saas2/header-icon/9.png"
               class="img-fluid set-abs img6 move-up-down">
          <img alt="icon" src="assets/images/saas2/header-icon/6.png"
               class="img-fluid set-abs img7 move-up-down">
          <div class="set-abs round move-right-left">
              <img alt="icon" src="assets/images/saas2/header-icon/10.png" class="img-fluid img8">
              <div class="set-abs inner-circle">
                  <img alt="" src="assets/images/saas2/header-icon/8.png" class="img-fluid img9">
              </div>
          </div>
          <div class="center-content set-abs bottom-content">
            <div class="bottom">
                <a class="down">
                    <img alt="" src="assets/images/saas2/header-icon/down.png"  (click)="onRedirect($event)" class="img-fluid">
                </a>
                <div>
                </div>
            </div>
          </div>
      </div>
  </div>
  <div>
  </div>
</section>
<!--header css end-->
