import { Component } from '@angular/core';

@Component({
  selector: 'app-youtube-session',
  templateUrl: './youtube-session.component.html',
  styleUrls: ['./youtube-session.component.scss']
})
export class YoutubeSessionComponent {

}
