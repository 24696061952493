<!-- subscribe section -->
<section id="contact" class="saas1 subscribe" [ngStyle]="{'background-image': 'url(assets/images/saas1/banner-2.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <div class="media">
                    <img class="" src="assets/images/saas1/subscribe-icon.png" alt="profile">
                    <div class="media-body">
                        <h3 class="mt-0 text-white">Teste Grátis por 3 Dias</h3>
                        <h6 class="text-white">
                            Experimente nosso surebet software grátis por 3 dias. Sem cartão de crédito necessário. Ative no Whatsapp hoje!
                        </h6>
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="subscribe-input">
                    <form (click)="openLink()">
                        <input id="useremail" type="email" placeholder="Ative pelo suporte" class="clickablelink" (click)="openLink()">
                        <input id="submit" type="submit" value="Eu quero!" (click)="openLink()">
                    </form>
                    <div class="text text-center d-flex">
                        <h6 class="text-white">3 dias grátis</h6><h6 class="text-white">Poucas vagas</h6><h6
                            class="text-white">Comece hoje</h6>
                    </div>
                </div>
            </div>
        </div>
        <!-- https://api.whatsapp.com/send?phone=5511916245916&text=Ol%C3%A1,%20vim%20do%20software%20e%20quero%20ativar%20meu%20plano! -->
    </div>
</section>
<!-- end subscribe section -->
