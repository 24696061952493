import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModernSassComponent } from './layouts/modern-sass/modern-sass.component';
import { from } from 'rxjs';
import { EnterpriceSassComponent } from './layouts/enterprice-sass/enterprice-sass.component';
export const routes: Routes = [
  {
    path: '',
    redirectTo: '/surebet',
    pathMatch: 'full',
  },
  {
    path: 'surebet',
    component: ModernSassComponent,
    data: {
      title: 'Software de Surebet - Blit Bet',
      description: 'O melhor software de surebet grátis - Encontre surebets sem custo por tempo limitado. Plataforma 24/7 com Surebets grátis e calculadora',
      keywords: ['surebet', 'software surebet', 'software surebet grátis', 'surebet grátis', 'arbitragem esportiva', 'surebet telegram', 'surebet arbitragem', 'surebet calculadora', 'surebet software', 'surebet na pratica']
    }
  },
  { path: 'politica', loadChildren: () => import('./views/politica/politica.module').then(m => m.PoliticaModule) },
  // {
  //   path: 'software',
  //   component: EnterpriceSassComponent
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
