<!-- testimonial section -->
<section id="ytb-session" class="saas1 testimonial videos testimonial-bg">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <div class="title">
                    <img src="assets/images/saas1/title-logo.png"  alt="title-logo" class="img-fluid">
                    <div class="main-title">
                        <h2>
                            Surebets com lucros de R$10.000,00
                        </h2>
                    </div>
                    <hr>
                    <div class="sub-title">
                        <p class="p-padding">Já perdeu dinheiro em apostas esportivas por depender da sorte? Chega de red! Descubra as surebets e tenha a garantia de lucro de 100%</p>
                        <p class="p-padding">Aprenda o passo a passo para usar o software de surebet da blit.bet e lucrar 10 mil reais no mês com um investimento inicial de R$200. Descubra como agora mesmo:</p>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="center-content div-ytb">
                    <iframe class="videoYtb" src="https://www.youtube.com/embed/_Bfdx4G8gPw?si=_-6R0g_pnlGoO05j" title="Software de Surebet" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- end testimonial section  -->
