import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-modern-sass',
  templateUrl: './modern-sass.component.html',
  styleUrls: ['./modern-sass.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModernSassComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private title: Title,
    private meta: Meta) { }

  ngOnInit() {
    this.title.setTitle(this.route.snapshot.data['title']);
    this.meta.updateTag({ name:'description', content:this.route.snapshot.data['content'] });
    this.meta.updateTag({ name: 'keywords', content: this.route.snapshot.data['keywords'].join(', ') });
  }

}
