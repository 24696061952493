import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modern-sass-testimonial',
  templateUrl: './modern-sass-testimonial.component.html',
  styleUrls: ['./modern-sass-testimonial.component.scss']
})
export class ModernSassTestimonialComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  testimonial = [
    {
      img:'assets/images/event/testimonial/L3-1.png',
      msgIcon:'assets/images/event/testimonial/message.png',
      name:'Rafael C. - ',
      designation:'Experiente',
      testimony:" É super intuitivo, e você não precisa ser um profissional para se dar bem. Sem falar no grupo do Telegram, onde compartilhamos dicas e experiências. É demais!"
    },
    {
      img:'assets/images/event/testimonial/L3-2.png',
      msgIcon:'assets/images/event/testimonial/message.png',
      name:'Ana J.- ',
      designation:'Iniciante',
      testimony:"O suporte é muito bom. Sempre tirei minhas dúvidas na hora. Esse software vale cada centavo que gastei"
    },
    {
      img:'assets/images/event/testimonial/L3-3.png',
      msgIcon:'assets/images/event/testimonial/message.png',
      name:'Vinicius L. - ',
      designation:'Intermediário',
      testimony:"É fácil de mexer, até eu que já tenho uma experiência me surpreendi. Eu recomendo pra geral que quer ganhar mais."
    }
  ]

  testimonialcarouselOptions= {
    items: 1,
    margin: 0,
    autoHeight: true,
    nav: true,
    dots: false,
    navText: ['<img alt="arrow" src="assets/images/music/gallery/gallery-icon/left.png">', '<img alt="arrow" src="assets/images/music/gallery/gallery-icon/right.png">'],
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true
  }
}
