import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutsRoutingModule } from './layouts-routing.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SwiperModule } from 'swiper/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMasonryModule } from 'ngx-masonry';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module'
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';

// Modern SASS Layout
import { ModernSassComponent } from './modern-sass/modern-sass.component';
import { ModernSassNavComponent } from './modern-sass/modern-sass-nav/modern-sass-nav.component';
import { ModernSassHeaderComponent } from './modern-sass/modern-sass-header/modern-sass-header.component';
import { ModernSassFeatureComponent } from './modern-sass/modern-sass-feature/modern-sass-feature.component';
import { ModernSassBusinessComponent } from './modern-sass/modern-sass-business/modern-sass-business.component';
import { ModernSassServicesComponent } from './modern-sass/modern-sass-services/modern-sass-services.component';
import { ModernSassScreenshotsComponent } from './modern-sass/modern-sass-screenshots/modern-sass-screenshots.component';
import { ModernSassPricingComponent } from './modern-sass/modern-sass-pricing/modern-sass-pricing.component';
import { ModernSassTestimonialComponent } from './modern-sass/modern-sass-testimonial/modern-sass-testimonial.component';
import { ModernSassBrandComponent } from './modern-sass/modern-sass-brand/modern-sass-brand.component';
import { ModernSassFooterComponent } from './modern-sass/modern-sass-footer/modern-sass-footer.component';

// Enterprice SAAS Layout
import { EnterpriceSassComponent } from './enterprice-sass/enterprice-sass.component';
import { EnterpriceSassNavComponent } from './enterprice-sass/enterprice-sass-nav/enterprice-sass-nav.component';
import { EnterpriceSassHeaderComponent } from './enterprice-sass/enterprice-sass-header/enterprice-sass-header.component';
import { EnterpriceSassFeatureComponent } from './enterprice-sass/enterprice-sass-feature/enterprice-sass-feature.component';
import { EnterpriceSassBuildComponent } from './enterprice-sass/enterprice-sass-build/enterprice-sass-build.component';
import { EnterpriceSassWorkComponent } from './enterprice-sass/enterprice-sass-work/enterprice-sass-work.component';
import { EnterpriceSassPriceComponent } from './enterprice-sass/enterprice-sass-price/enterprice-sass-price.component';
import { EnterpriceSassTestimonialComponent } from './enterprice-sass/enterprice-sass-testimonial/enterprice-sass-testimonial.component';
import { EnterpriceSassSubscribeComponent } from './enterprice-sass/enterprice-sass-subscribe/enterprice-sass-subscribe.component';
import { EnterpriceSassFaqComponent } from './enterprice-sass/enterprice-sass-faq/enterprice-sass-faq.component';
import { EnterpriceSassClientComponent } from './enterprice-sass/enterprice-sass-client/enterprice-sass-client.component';
import { EnterpriceSassFooterComponent } from './enterprice-sass/enterprice-sass-footer/enterprice-sass-footer.component';
import { EnterpriceSassCopyrightComponent } from './enterprice-sass/enterprice-sass-copyright/enterprice-sass-copyright.component';
import { YoutubeSessionComponent } from './enterprice-sass/youtube-session/youtube-session.component';



@NgModule({
  declarations: [
    ModernSassComponent, ModernSassNavComponent, ModernSassHeaderComponent, ModernSassFeatureComponent, ModernSassBusinessComponent, ModernSassServicesComponent, ModernSassScreenshotsComponent, ModernSassPricingComponent, ModernSassTestimonialComponent, ModernSassBrandComponent, ModernSassFooterComponent,
    EnterpriceSassComponent, EnterpriceSassNavComponent, EnterpriceSassHeaderComponent, EnterpriceSassFeatureComponent, EnterpriceSassBuildComponent, EnterpriceSassWorkComponent, EnterpriceSassPriceComponent, EnterpriceSassTestimonialComponent, EnterpriceSassSubscribeComponent, EnterpriceSassFaqComponent, EnterpriceSassClientComponent, EnterpriceSassFooterComponent, EnterpriceSassCopyrightComponent, YoutubeSessionComponent
  ],

  imports: [
    CommonModule,
    LayoutsRoutingModule,
    SwiperModule,
    CarouselModule,
    NgbModule,
    GalleryModule,
    LightboxModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMasonryModule
  ],

  exports: [],
})

export class LayoutsModule { }
