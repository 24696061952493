import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModernSassComponent } from './modern-sass/modern-sass.component';
import { EnterpriceSassComponent } from './enterprice-sass/enterprice-sass.component';

const routes: Routes = [
  {
    path: '',
    component: ModernSassComponent,
    data: {
      title: "Software Surebet - Blit Bet"
    }
  },
    {
    path: '',
    component: EnterpriceSassComponent,
    data: {
      title: "Software Surebet - Blit Bet"
    }
  },
  { 
    path: '**', 
    redirectTo: "/surebet",
    pathMatch: 'full'
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutsRoutingModule { }
